<template>
	<form v-on:submit.prevent="sendAnswer()" class="needs-validation was-validated" ref="userForm" novalidate>
		<div class="row align-items-end justify-content-center">
			<div class="col-lg-5">
				<div class="form-row">
					<div class="mb-2 col-md-12">
						<input type="text" id="nombre"  name="nombre" v-model="encuesta.nombre" placeholder="Nombre" class="form-control" ref="focusInput" required>
					</div>

					<div class="col-md-12 mb-2">
						<input type="text" id="apellido" name="apellido" v-model="encuesta.apellido" placeholder="Apellido" class="form-control" required>
					</div>
					<div class="col-md-12 mb-2">
						<input type="email" id="correo" name="correo" v-model="encuesta.mail" maxlength="100" placeholder="Correo electrónico" class="form-control" required>
					</div>
					<div class="col-md-12 mb-2">
						<input type="text" id="telefono" name="telefono" v-model="encuesta.telefono" placeholder="Teléfono" class="form-control" required>
					</div>

					<div class="col-md-12 mb-2">
						<input type="text" id="nit" name="nit" v-model="encuesta.nit" placeholder="NIT" class="form-control" required>
					</div>

					<div class="col-md-12 mb-1" v-if="!encuesta.tipoFormulario || tipoDF == 'vehículo'">
						<input type="text" id="marca" name="marca" v-model="encuesta.marca" placeholder="Marca del vehículo" class="form-control" required>
					</div>

					<div class="col-md-12 mb-1" v-if="!encuesta.tipoFormulario || tipoDF == 'vehículo'">
						<input type="text" id="anio" name="anio" v-model="encuesta.anio" placeholder="Año de vehículo" class="form-control" required>
					</div>

					<div class="col-md-12 mb-1" v-if="!encuesta.tipoFormulario || tipoDF == 'vehículo'">
						<input type="text" id="linea" name="linea" v-model="encuesta.linea" placeholder="Línea de vehículo" class="form-control" required>
					</div>

					<div class="col-lg-12 mb-1">
						<div v-if="encuesta.tipoFormulario && !(tipoDF == 'vehículo')">
							<textarea type="text" id="comentario" name="comentario" v-model="encuesta.comentario" placeholder="Tu mensaje/comentario" class="form-control" rows="3"></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-5" v-if="(tipoDF == 'vehículo' || !encuesta.tipoFormulario)">
				<div class="row">
					<div class="col-md-12 mb-2">
						<p>Tipo de vehículo</p>
						<div class="custom-control custom-radio mb-1">
							<input type="radio" id="rodado" name="tipo" v-model="encuesta.tipo" class="custom-control-input" value="Rodado" required>
							<label class="custom-control-label" for="rodado">Rodado</label>
						</div>
						<div class="custom-control custom-radio">
							<input type="radio" id="agencia" name="tipo" v-model="encuesta.tipo" class="custom-control-input" value="Agencia" required>
							<label class="custom-control-label" for="agencia">Agencia</label>
						</div>
					</div>

					<div class="col-md-12 mb-2">
						<label class="mb-0 text-muted text-xs" for="monto">Valor del carro</label>
						<CurrencyInput type="text" id="monto" name="monto" v-model="encuesta.monto" placeholder="Valor del carro" class="form-control" @change="montoHandler" required />
					</div>

					<div class="col-md-12 mb-2" v-if="tipoDF != 'vehículo'">
						<label class="mb-0 text-muted text-xs" for="enganche">Enganche</label>
						<CurrencyInput type="text" id="enganche" name="enganche" v-model="encuesta.enganche" placeholder="Enganche" class="form-control" required />
					</div>

					<div class="col-md-12 mb-1" v-if="tipoDF != 'vehículo'">
						<div class="d-flex justify-content-between w-100 py-2">
							<span class="font-weight-bold">Monto a solicitar: </span>	
							<span>
								{{$filters.currency(montoRequerir)}}
							</span>
						</div>
					</div>

					<div class="col-md-12 mb-2">
						<textarea type="text" id="comentario" name="comentario" v-model="encuesta.comentario" placeholder="Tu mensaje/comentario" class="form-control" :rows="3"></textarea>
					</div>
				</div>
			</div>
			<div class="mt-3">
				<router-link :to="{name:'precalifique.index'}" class="btn btn-link mb-2" v-if="false">
					Cerrar
				</router-link>
				<button type="submit" class="btn rounded-pill font-weight-bold btn-secondary text-primary" :disabled="loadingForm || !validateForm">
					<BtnLoading v-if="loadingForm" />
					<span v-else>
						Precalificar
					</span>
				</button>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const CurrencyInput = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		props: ['tipo', 'modelValue', 'enganche', 'tipoFormulario', 'predionombre', 'predio'],

		emits: ['update:modelValue'],

		mounted(){
			this.setDefault()
			this.monto = this.modelValue
		},

		components: {
			BtnLoading,
			CurrencyInput
		},

		data(){
			return {
				loadingForm: false,
				validateForm: false,
				
				encuesta: {
					tipoFormulario: true,

					nombre: '',
					apellido: '',
					mail: '',
					telefono: '',
					nit: '',
					direccion: '',
					monto: 1,
					comentario: '',

					marca: '',
					anio: '',
					linea: '',
					tipo: '',
					enganche: 0,
				}
			}
		},

		methods: {
			sendAnswer(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingForm = true

				var encuesta = this.encuesta
				encuesta.monto = this.montoRequerir

				if(encuesta.tipoFormulario){
					encuesta.monto = 1
					encuesta.plazo = ''
					encuesta.enganche = 0
					encuesta.marca = ''
					encuesta.anio = ''
					encuesta.linea = ''
					encuesta.color = ''
					//encuesta.comentario = ''
				}

				var tipo = ''
				var tipoValue = this.tipoDF
				
				if(this.tipoDF == 'auto compras seminuevos'){
					tipo = 4
				}

				if(this.tipoDF == 'vehículo'){
					tipo = 1
				}

				if(this.tipoDF == 'predio'){
					tipo = 4
					tipoValue = 'predio '+(this.predionombre || '')
				}

				this.$store.dispatch('addPrecalificacion', {
					encuesta: encuesta,
					usuario: this.currentUser,
					nombretipo: tipoValue,
					tipo: tipo,
					predio: this.predio || ''
				}).then(response => {
					this.encuesta.nombre =''
					this.encuesta.apellido =''
					this.encuesta.mail =''
					this.encuesta.telefono =''
					this.encuesta.nit =''
					this.encuesta.direccion =''
					this.encuesta.monto = 1
					this.encuesta.comentario =''
					this.encuesta.cargo = ''

					this.encuesta.marca = ''
					this.encuesta.anio = ''
					this.encuesta.linea = ''
					this.encuesta.tipo = ''
					this.encuesta.enganche = 0
					
					if(this.tipoDF == 'auto compras seminuevos'){
						this.$router.push({name: 'auto-compras.gracias'})
					}

					if(this.tipoDF == 'vehículo'){
						this.$router.push({name: 'vehiculos.gracias'})
					}

					if(this.tipoDF == 'predio'){
						window.toastr.info('Información envíada exitosamente.', 'Aviso')
					}

					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingForm = false
				})
			},

			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			},
			setDefault(){
				this.encuesta.nombre = this.currentUser.nombre
				this.encuesta.apellido = this.currentUser.apellido
				this.encuesta.mail = this.currentUser.mail
				this.encuesta.telefono = this.currentUser.telefono
				this.encuesta.nit = ''
				this.encuesta.cargo = ''

				this.encuesta.monto = this.query.monto || 1
				this.encuesta.enganche = this.query.enganche || 0
				
				this.encuesta.marca = this.query.marca || ''
				this.encuesta.anio = this.query.anio || ''
				this.encuesta.linea = this.query.modelo || ''
				this.encuesta.tipo = this.query.tipo || ''

				this.encuesta.comentario = ''

				this.setTipoDf()
			},
			montoHandler(ev){
				if(typeof ev == 'number'){
					this.$emit('update:modelValue', ev)
				}
			},

			setTipoDf(){
				if(this.tipoDF == 'auto compras seminuevos' || this.tipoDF == 'predio'){
					this.encuesta.tipoFormulario = false
				}else{
					this.encuesta.tipoFormulario = true
				}
			},
		},

		computed:{
			tipoDF(){
				return this.tipo || ''
			},

			currentUser(){
				return this.$store.getters.currentUser
			},

			montoRequerir(){
				if(this.tipoDF == 'vehículo'){
					return (this.encuesta.monto || 0)
				}

				return (this.encuesta.monto || 0) - (this.encuesta.enganche || 0)
			},

			query(){
				return this.$route.query || {}
			},
		},

		watch: {
			'currentUser':{
				handler(val){
					this.setDefault()
					return val
				},
				deep: true
			},
			'encuesta':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			},
			modelValue(val){
				this.encuesta.monto = parseFloat(val || 1)
			},
			enganche(val){
				this.encuesta.enganche = val || 0
			},

			tipoFormulario(val){
				this.encuesta.tipoFormulario = val
			},

			tipoDF(val){
				this.setTipoDf()
				return val
			}
		}
	}
</script>